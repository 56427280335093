import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import { PageHeader, Services } from "../components/sections"
import {
  HelpCollabration,
  callcenter,
  hostedPbx,
  music,
  onPremise,
  teamcalling,
  unifiedCollabration,
  videoConference,
} from "../images/unifiedcomm"
import {
  BlackSection,
  DetailSection,
  IntroSection,
  OfferSection,
  Points,
} from "../components/structure"
import { AboutCard } from "../components/elements"

const weOffer = [
  {
    id: 1,
    src: hostedPbx,
    title: "Hosted PBX",
    content:
      "Moving to a hosted PBX system leads to a plethora of benefits for your organisation. By letting us take care of hosting your phone network, we’ll cover installation, operation, and maintenance so you can concentrate on the more demanding aspects of your business.",
    order: 0,
  },
  {
    id: 2,
    src: onPremise,
    title: "On-premise PBX",
    content:
      "Let us upgrade your phone system with our on-premise PBX service. This is an ideal option for some organisations because it removes the need for a strong data connection and means that the phone system is owned and managed internally by your business. Whatever phone system you choose, we offer premium setup, consultancy, and maintenance.",
    order: 1,
  },
  {
    id: 3,
    src: teamcalling,
    title: "Microsoft Teams Calling",
    content:
      "With today’s geographical restrictions, your business needs an effective communication method. Enter Microsoft Teams, a platform that allows you to increase the productivity of your organisation through tools like workplace chat, video meetings, file storage, and calling. We’ll install, configure, and connect your office to Microsoft Teams so your business can continue to thrive, no matter what’s going on outside.",
    order: 0,
  },
  {
    id: 4,
    src: callcenter,
    title: "Call Centre",
    content:
      "Having an efficient call centre gives your customers the chance to interact with your team directly and build brand loyalty. Our call centre services equip your business with a powerful communications platform that allows you to quickly respond to customers, troubleshoot problems, and maintain the solid reputation of your company.",
    order: 1,
  },
  {
    id: 5,
    src: videoConference,
    title: "Video Conferencing Solutions",
    content:
      "Video conferencing is more relevant for businesses today than it’s ever been. Let us provide you with state-of-the-art video conferencing solutions so you can keep your team connected, collaborate with personnel all over the world, and continue to operate your business efficiently.",
    order: 0,
  },
  {
    id: 6,
    src: music,
    title: "On-Hold Music",
    content:
      "When your business is booming, you won’t be able to respond to all your customers right away. But with on-hold music, you can show your callers that you still appreciate their time and care about their needs. We’ll set your business up with effective on-hold music to increase customer satisfaction and brand loyalty.",
    order: 1,
  },
]

const points = [
  {
    id: 1,
    point:
      "Allowing your team to communicate remotely through video conferencing and workplace chats",
  },
  {
    id: 2,
    point:
      "Providing a reliable phone system that helps your business to communicate and collaborate",
  },
  {
    id: 3,
    point:
      "Improving the customer service aspect of your business with call centre technology and on-hold music",
  },
]
export default function Collabration() {
  const data = useStaticQuery(graphql`
    query {
      index: file(relativePath: { eq: "headers/collabration.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const index = [
    data.index.childImageSharp.fluid,
    `linear-gradient(0deg,
      rgba(0, 0, 0, 0.30),
      rgba(0, 0, 0, 0.30))`,
  ].reverse()
  return (
    <Layout>
      <SEO title="Unified Communication" />
      <PageHeader
        fluid={index}
        title="Connect with us, connect with the world"
        catchy="Unified Communications"
        subtitle="increase customer satisfaction and brand loyalty"
      />
      <IntroSection
        title="Unified Communications"
        content="Communication is key. For your business to reach its maximum potential, it’s crucial that your people are able to interact and collaborate with ease, regardless of where they’re located. That’s why we offer premium unified communication solutions, ensuring that your organisation leverages powerful tools like video conferencing and team calling to your absolute advantage."
        src={unifiedCollabration}
      />
      <BlackSection title="Increase customer satisfaction and brand loyalty" />
      <IntroSection
        title="How we will help you?"
        content="Since the Covid-19 disaster, unified communications have become a necessity for nearly every business. We provide a range of unified communication solutions that strengthen your business by allowing your team to interact effortlessly. As part of our services, we offer hosted and on-premise phone systems plus innovative tools like Microsoft Teams and video conferencing. We also provide your business with the solutions you need to offer premium customer service, including call centre setup and on-call music. Keep your business flourishing in spite of physical limitations with a strong unified communications system."
        src={HelpCollabration}
      />
      <OfferSection title="What we offer:">
        {weOffer.map((item, id) => (
          <AboutCard
            key={item.id}
            order={item.order}
            src={item.src}
            to={item.to}
            title={item.title}
            content={item.content}
          />
        ))}
      </OfferSection>
      <DetailSection
        paragraph1="Unified communications have always benefited businesses, but following the effects of the Covid-19 pandemic, this element is now critical. Without strong and reliable tools through which your team can communicate, hurdles like lockdowns and social distancing can mean the end of your business. Protect your organisation and help it to keep thriving with our innovative unified communications solutions that will add value to your company by:"
        paragraph2="Contact us today to discuss the unified communications that you need and how we can work together to keep your business prosperous in an uncertain world."
      >
        {points.map((item, id) => (
          <Points key={item.id}>{item.point}</Points>
        ))}
      </DetailSection>
      <Services />
      <BlackSection title="Call us today to connect and collaborate" />
    </Layout>
  )
}
